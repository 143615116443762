import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"end"}},[_c(VPagination,{attrs:{"length":Math.ceil(_vm.totalCount / _vm.itemsPerPage),"total-visible":7},on:{"input":_vm.pageSelected},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_vm._v(" "),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders(_vm.contents, _vm.dateContents),"items":_vm.tableDatas(_vm.contents, _vm.dateContents),"page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.pv",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.numberFormat(item.pv))+"\n        ")]}},{key:"item.uu",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.numberFormat(item.uu))+"\n        ")]}},{key:"item.num",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.numberFormat(item.num))+"\n        ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }