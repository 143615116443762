import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{attrs:{"color":"white","elevation":"0"}},[_c(VToolbarTitle,{staticClass:"px-2"},[_c('Logo')],1),_vm._v(" "),_c('div',{staticClass:"ml-4"},[_c('LayoutAccountsMenu',{attrs:{"current-account":_vm.currentAccount,"account-list":_vm.accountList}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('LayoutProfileMenu',{attrs:{"current-user-name":_vm.currentUserName}})],1),_vm._v(" "),_c(VMain,[_c(VSheet,{staticClass:"d-flex"},[_c(VSheet,{attrs:{"width":"250px","color":"#EEFAFA"}},[_c('LayoutGlobalMenu')],1),_vm._v(" "),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"width":"85%","min-height":"94vh"}},[_c(VSheet,{staticClass:"py-4 px-8"},[_vm._t("default")],2),_vm._v(" "),_c(VSheet,[_c(VDivider),_vm._v(" "),_c('LayoutFooter')],1)],1)],1),_vm._v(" "),_c(VDialog,{attrs:{"max-width":_vm.dialog.width || '650px'},model:{value:(_vm.dialog.display),callback:function ($$v) {_vm.$set(_vm.dialog, "display", $$v)},expression:"dialog.display"}},[_c('div',{staticClass:"white pa-4"},[_c(_vm.dialog.componentName,_vm._g(_vm._b({tag:"component"},'component',_vm.dialog.props,false),_vm.dialog.on))],1)])],1),_vm._v(" "),_c(VNavigationDrawer,{staticStyle:{"zIndex":"11"},attrs:{"app":"","fixed":"","temporary":"","right":"","color":"grey lighten-4","width":_vm.drawer.width || '45%',"permanent":_vm.drawer.permanent},on:{"input":_vm.changeDrawer},model:{value:(_vm.drawer.display),callback:function ($$v) {_vm.$set(_vm.drawer, "display", $$v)},expression:"drawer.display"}},[_c(_vm.drawer.componentName,_vm._b({tag:"component"},'component',_vm.drawer.props,false))],1),_vm._v(" "),_c(VOverlay,{attrs:{"value":_vm.overlay.display,"z-index":"99999"}},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }